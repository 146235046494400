import {find, includes, map} from 'lodash'
import {useLocation, useNavigate} from 'react-router-dom'
import {SHARED_USERS_DATA_PATH, USERS_DATA_PATH} from '../../../../helpers'
import {KTSVG} from '../../../../helpers/components/KTSVG'
import {HorizontalMenu} from './HorizontalMenu'

export function MenuInner() {
  const {pathname} = useLocation()
  const navigate = useNavigate()

  const menu = [
    {
      label: 'Data người dùng',
      pathname: USERS_DATA_PATH.MAIN,
      activePaths: [USERS_DATA_PATH.MAIN, SHARED_USERS_DATA_PATH.MAIN],
    },
  ]

  return (
    <div className='app-navbar flex-shrink-0'>
      <div
        className='app-navbar-item cursor-pointer symbol h-full'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <div className='me-3 text-white fw-bold'>
          <KTSVG path='/media/icons/modules/total-icon.svg' className='svg-icon-2x' />
        </div>
      </div>
      <HorizontalMenu />
      <div className='d-flex align-items-center text-white fw-bold gap-3'>
        {map(menu, (menuItem, menuItemIndex) => {
          return (
            <div
              key={menuItemIndex}
              style={{
                color: find(menuItem.activePaths, (activePath) => includes(pathname, activePath))
                  ? '#ffe6b4'
                  : '',
                cursor: 'pointer',
              }}
              className='menu-item me-lg-1'
              onClick={() => navigate(`/${menuItem.pathname}`)}
            >
              <div className='menu-label fs-4 py-3'>
                <span className='menu-title'>{menuItem.label}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
